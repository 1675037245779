@font-face {
  font-family: 'NotoKufiArabic';
  src: url('./assets/NotoKufiArabic-VariableFont_wght.ttf') format('truetype');    
  font-weight: 700;
}

body {
  font-family: 'NotoKufiArabic', sans-serif;
  background-color: #1D2D3C;
  --primary: #1D2D3C;
  --secondary: #FCBB43;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}